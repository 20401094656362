import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import styled, { createGlobalStyle } from 'styled-components';
import { Typography, Container, Snackbar, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import newLogo from '../components/NewLogo_BLANK-outer.png';
import { useAuth } from '../hooks/useAuth';
import { usePopup } from '../PopupContext';
import StyledButton from '../components/StyledButton';

const theme = createTheme({
  palette: {
    primary: {
      main: '#62238C',
    },
    secondary: {
      main: '#BF4B81',
    },
  },
  typography: {
    fontFamily: 'Heebo, sans-serif',
  },
});

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Heebo', sans-serif;
    background-color: #ffffff;
    direction: rtl;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled(Container)`
  padding: 2rem;
  background: #ffffff;
  text-align: center;
  max-width: 1200px;
  margin: 2rem auto;
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${newLogo}) no-repeat center;
    background-size: cover;
    opacity: 0.05;
    pointer-events: none;
    z-index: 0;
  }
`;

const PageTitle = styled(Typography)`
  font-size: 0.8rem;
  font-weight: light;
  color: #62238C;
  margin-bottom: 1.2rem;
  position: relative;
  z-index: 1;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  
  @media (min-width: 480px) {
    font-size: 1rem;
  }
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
  
  &:hover {
    transform: scale(1.05);
    text-shadow: 2px 2px 4px rgba(98, 35, 140, 0.3);
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: relative;
  z-index: 1;
`;

const CourseDescription = styled.div`
  font-size: 1rem;
  color: #333;
  max-width: 100%;
  text-align: right;
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  h2 {
    font-size: 1.5rem;
    color: #62238C;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding: 2rem;
    max-width: 800px;

    h2 {
      font-size: 2rem;
    }
  }
`;

const CountdownContainer = styled.div`
  font-size: 1rem;
  color: #bf4b81;
  margin-top: 1rem;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const LoadingSpinner = styled(CircularProgress)`
  color: #62238C;
`;

const LargeStyledButton = styled(StyledButton)`
  padding: 20px 40px;
  font-size: 20px;

  @media (max-width: 768px) {
    padding: 18px 36px;
    font-size: 18px;
  }
`;

const PriceDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #888;
`;

const DiscountedPrice = styled.span`
  font-weight: bold;
  color: #62238C;
`;

const RegularPrice = styled.span`
  font-weight: bold;
  color: #62238C;
`;

const CourseDetailsPage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { openLoginPopup, openRegisterPopup, openPurchasePopup } = usePopup();
  const [finalPrice, setFinalPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const fetchCourseAndDiscount = async () => {
      try {
        console.log('Fetching course details for courseId:', courseId);
        const { data: courseData, error: courseError } = await supabase
          .from('courses')
          .select('*')
          .eq('id', courseId)
          .single();

        if (courseError) {
          console.error('Error fetching course:', courseError);
          setSnackbarMessage('שגיאה בטעינת פרטי הקורס.');
          setSnackbarOpen(true);
          return;
        }

        console.log('Course data fetched:', courseData);
        setCourse(courseData);
        setOriginalPrice(courseData.price);

        if (user) {
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('discount')
            .eq('id', user.id)
            .single();

          if (userError) {
            console.error('Error fetching user discount:', userError);
            setSnackbarMessage('שגיאה בטעינת הנחת המשתמש.');
            setSnackbarOpen(true);
          } else {
            console.log('User discount fetched:', userData);
            const userDiscount = userData.discount || 0;
            const courseDiscount = courseData.discount || 0;
            const totalDiscount = Math.max(userDiscount, courseDiscount);
            setDiscountPercentage(totalDiscount);
            const discountedPrice = calculateDiscountedPrice(courseData.price, totalDiscount);
            setFinalPrice(discountedPrice);
          }
        } else {
          setFinalPrice(courseData.price);
        }

        // חישוב הזמן שנותר עד לסיום ההנחה
        if (courseData.discountExpirationDate) {
          const expirationDate = new Date(courseData.discountExpirationDate);
          const updateTimeLeft = () => {
            const now = new Date();
            const timeDiff = expirationDate - now;
            if (timeDiff <= 0) {
              setTimeLeft('ההנחה הסתיימה');
            } else {
              const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
              const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
              setTimeLeft(`${days} ימים, ${hours} שעות, ${minutes} דקות, ${seconds} שניות`);
            }
          };
          updateTimeLeft();
          const timerId = setInterval(updateTimeLeft, 1000);
          return () => clearInterval(timerId);
        }

      } catch (error) {
        console.error('Unexpected error:', error);
        setSnackbarMessage('שגיאה בלתי צפויה בטעינת הקורס.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseAndDiscount();
  }, [courseId, user]);

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const handlePurchaseClick = async () => {
    console.log('Purchase button clicked');
    if (!user) {
      console.log('User is not logged in, opening register popup');
      openRegisterPopup(true);  // true indicates it's from course details
      return;
    }

    if (finalPrice === 0) {
      console.log('Course is free, adding to user\'s courses');
      await addCourseToUserCourses();
    } else {
      console.log('User is logged in, opening purchase popup');
      openPurchasePopup({ ...course, finalPrice });
    }
  };

  const addCourseToUserCourses = async () => {
    if (!user) {
      console.error('Cannot add course: User is not logged in');
      setSnackbarMessage('יש להתחבר כדי להוסיף את הקורס');
      setSnackbarOpen(true);
      return;
    }

    const { data, error } = await supabase
      .from('enrollments')
      .insert({
        user_id: user.id,
        course_id: course.id,
        current_lesson: 0,
        amount_paid: 0,
        course_title: course.title,
        total_lessons: course.total_lessons
      });

    if (error) {
      console.error('Error adding course to user\'s courses:', error);
      setSnackbarMessage('אירעה שגיאה בהוספת הקורס. אנא נסה שוב.');
    } else {
      console.log('Course added successfully:', data);
      setSnackbarMessage('הקורס נוסף בהצלחה לאזור האישי שלך!');
    }
    setSnackbarOpen(true);
  };
  
  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PageContainer>
          <LoadingSpinner />
        </PageContainer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PageContainer>
        <PageTitle variant="h1">פרטי הקורס</PageTitle>
        <PageContent>
          <CourseDescription>
            <h2>{course.title}</h2>
            <p>{course.description}</p>
            <p>{course.details}</p>
            <p>משך זמן: {course.duration}</p>
            {/* הוספת ה-iframe להקרנת הטריילר של הקורס */}
            <div style={{ marginBottom: '1.5rem', maxWidth: '100%', textAlign: 'center' }}>
              <iframe
                width="100%"
                height="315"
                src={getEmbedUrl(course.trailerUrl)} // יצירת קישור ה-Embed
                title="Course Trailer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
 {/* הצגת סיבת ההנחה רק אם יש הנחה */}
 {discountPercentage > 0 && course.discountReason && (
              <p>סיבת ההנחה: {course.discountReason}</p>
            )}
            {course.discountExpirationDate && (
              <CountdownContainer>
                <p>תוקף ההנחה מסתיים בעוד: {timeLeft}</p> {/* הצגת ספירה לאחור */}
              </CountdownContainer>
            )}
            
            <PriceDisplay>
              {discountPercentage > 0 ? (
                <>
                  <OriginalPrice>{originalPrice} ש״ח</OriginalPrice>
                  <DiscountedPrice>{finalPrice.toFixed(2)} ש״ח</DiscountedPrice>
                </>
              ) : (
                <RegularPrice>{finalPrice.toFixed(2)} ש״ח</RegularPrice>
              )}
            </PriceDisplay>
            <p>מספר שיעורים: {course.total_lessons}</p>
          </CourseDescription>
          <LargeStyledButton as="button" onClick={handlePurchaseClick} isprimary="true">
            {finalPrice === 0 ? 'הוסף לקורסים שלי' : 'רכוש עכשיו'}
          </LargeStyledButton>
        </PageContent>
        <Snackbar
          open={snackbarOpen}
          message={snackbarMessage}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        />
      </PageContainer>
    </ThemeProvider>
  );
};

export default CourseDetailsPage;
