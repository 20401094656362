import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { Typography, Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#62238C',
    },
    secondary: {
      main: '#BF4B81',
    },
  },
  typography: {
    fontFamily: 'Heebo, sans-serif',
  },
});

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap');
  
  body {
    font-family: 'Heebo', sans-serif;
    background-color: #ffffff;
    direction: rtl;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled(Container)`
  padding: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const SectionContainer = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const ContactAndPolicyPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageContainer maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h2" component="h1" gutterBottom color="primary" align="center" sx={{
            fontWeight: 'bold',
            marginBottom: 4,
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
          }}>
            פרטי קשר ותקנון ביטולים
          </Typography>

          <SectionContainer
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Typography variant="h4" gutterBottom color="secondary">
              פרטי קשר
            </Typography>
            <Typography variant="body1" paragraph>
              טלפון: 054-7667775<br />
              אימייל: Triroars@gmail.com<br />
              כתובת: צבי סגל 20א, אשקלון, ישראל
            </Typography>
          </SectionContainer>

          <SectionContainer
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Typography variant="h4" gutterBottom color="secondary">
              תקנון ביטולים
            </Typography>
            <Typography variant="body1" paragraph>
              ניתן לבטל את רכישת הקורס בתוך 14 יום ממועד הרכישה, בתנאי שהקורס לא נפתח או נצפה. הביטול יעשה בהודעה בכתב לכתובת האימייל שלנו. לאחר קבלת הודעת הביטול, יוחזר סכום הרכישה בתוך 14 ימי עסקים לאמצעי התשלום ממנו בוצעה הרכישה.
            </Typography>
          </SectionContainer>

          <SectionContainer
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Typography variant="h4" gutterBottom color="secondary">
              מדיניות פרטיות
            </Typography>
            <Typography variant="body1" paragraph>
  השימוש באתר Triroars.co.il (להלן: “האתר” או “המפעילה”) כפוף להוראות התקנון בדף זה. חשוב להקפיד ולעיין בתקנון מאחר וההצטרפות מעידה על הסכמתך לתנאי התקנון המפורטים להלן. אישור המשתמש כי קרא והסכים להוראות התקנון ולא תהא לו ו/או למי מטעמו ו/או בשמו כל תביעה כנגד מפעילת האתר.
</Typography>
<Typography variant="body1" paragraph>
  הסכם ומדיניות זו חלה על האופן שבו מפעיל האתר אוסף, עושה שימוש, מחזיק וחושפת מידע שנאסף מהמשתמשים והצרכנים (ולהלן בלשון יחיד: “משתמש” או “מזמין” באתר) באתר Triroars.co.il (להלן: “האתר” או “המפעילה”). הסכם מדיניות פרטיות זו חלה על האתר ועל כל המוצרים והשירותים המוצעים בו על ידי עידו שגב.
</Typography>
<Typography variant="body1" paragraph>
  כדי להגן על המידע האישי של המשתמש, אנו משקיעים את מיטב מאמצינו באבטחת מידע שתמנע את איבודו, תגביל את הגישה אליו ותמנע את שינויו ע”י גורמים זרים, וזאת על ידי שימוש בהצפנת SSL ואחסון המידע בהצפנת AES-256.
</Typography>
<Typography variant="body1" paragraph>
  באתר אנו עושים שימוש בעוגיות (cookies) כדי לשדרג את חוויית המשתמש. הדפדפן של המשתמש שומר במחשבו קבצי עוגיות למטרות תיעוד ומעקב אחר מידע הנוגע למשתמשים. בהגדרות הדפדפן, המשתמש רשאי כרצונו לבטל את שמירת העוגיות או לדרוש קבלת התראות כאשר עוגיות נשלחות אליו. לידיעתכם, במקרה שתפעלו בדרך זו, ייתכן שחלקים ושירותים מסוימים באתר לא יפעלו כראוי.
</Typography>
<Typography variant="body1" paragraph>
  על פי חוק הגנת הפרטיות, תשמ”א-1981, לכל אדם הזכות לעיין במידע המוחזק עליו במאגר המידע של המפעילה. משתמש שעיין במידע עליו ומצא שהמידע אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן ו/או לעדכן ו/או למחוק את המידע המוחזק עליו כאמור לעיל.
</Typography>
<Typography variant="body1" paragraph>
  פנייה כזו יש להפנות למפעילה באתר עצמו בדף יצירת קשר. מובהר בזאת כי ייתכנו שינויים בפרטי הקשר אשר צוינו לעיל ובמועדי זמינות המפעילה על פי שיקול דעתה הבלעדי וללא מתן הודעה מוקדמת ולמשתמש לא יהיה עילה ו/או טענות בשל כך.
</Typography>
<Typography variant="body1" paragraph>
  אם המידע שבמאגר המפעילה משמש לצורך פניה אישית למשתמש, הוא רשאי על פי חוק לדרוש בכתב כי המידע המתייחס אליו יימחק ממאגר המידע.
</Typography>
<Typography variant="body1" paragraph>
  המפעילה שומרת את הזכות לשנות, למחוק ולעדכן מסמך זה וחלקים ממנו בכל זמן וללא התרעה.
</Typography>
<Typography variant="body1" paragraph>
  המשתמש מסכים לשפות ולפצות את חברת Triroars ומפעיליה, מנהליה, עובדיה ושותפיה מפני כל תביעה ו/או דרישה, לרבות הוצאות ושכר טרחת עורכי דין, הנובעים או הקשורים לפעילותו שלא על פי תנאי שימוש אלה ו/או שלא כדין.
</Typography>
<Typography variant="body1" paragraph>
  לאחר אישור פרטי ההזמנה ע”י המשתמשים ומעבר לדף ביצוע התשלום, המשתמש ירשום את כל הפרטים בכל שלב ושלב כנדרש ולאחר אישור התשלום ע”י חברת הסליקה, ימסרו פרטי ההזמנה למשתמש ע”י מפעילי האתר ומהווה סיום העסקה. פרטי הזהות של המשתמש בעל הכרטיס האשראי הם תנאי לאישור התשלום וע”פ החוק בעל הכרטיס הוא אדם בוגר מעל גיל 18.
</Typography>
<Typography variant="body1" paragraph>
  תנאי לרכישה באתר כי המשתמש הינו בן 18 שנים ומעלה.
</Typography>
<Typography variant="body1" paragraph>
  המשתמש מתחייב שאינו יעשה שימוש לא חוקי, בלתי מאושר, מסחרי ושמנוגד לחוקים במדינה בו הוא גר (כולל חוקים הנוגעים לזכויות יוצרים).
</Typography>
<Typography variant="body1" paragraph>
  המשתמש מבין, שכאשר רוכש מוצר דיגיטלי, תפתח בפניו הגישה בפרק זמן שלא עולה על יום עסקים אחד, ובמקרים חריגים, ועל פי שיקול דעת האתר ותלות בגורמי צד שלישי אף יותר מכך.
</Typography>
<Typography variant="body1" paragraph>
  תנאי התשלום ייקבעו על פי שיקול דעתו הבלעדי של האתר. אלא אם כן הוסכם אחרת, התשלום יתקבל על ידי האתר לפני אישור ההזמנה. האתר על פי שיקוליו הבלעדיים, רשאי לבטל ולחסום הזמנות.
</Typography>
<Typography variant="body1" paragraph>
  יובהר כי המועד הקובע לעניין מחיר המוצר הוא מועד אישור ההזמנה על ידי המזמין ולא מועד הוספתו לסל הקניות. המפעילה רשאית לשנות את דרכי ו/או אמצעי התשלום ו/או את דרכי ביצוע ההזמנה בכל עת לפי שיקול דעתה הבלעדי ובלא מתן הודעה מוקדמת מבלי שלמשתמש יהיו טענות נגדה בשל כך.
</Typography>
<Typography variant="body1" paragraph>
  אין האתר ומפעיליו, מנהליו, עובדיו ושותפיו אחראים על נזקים ו/או הפסדים הנגרמים עקב קבלת החלטתו של משתמש בגין רכישת מוצר ו/או שרות שמוצע על ידי האתר.
</Typography>
<Typography variant="body1" paragraph>
  האתר שומר זכות לסרב למתן שירות לכל משתמש מכל סיבה שיראה לנכון בכל זמן. על המשתמש להבין כי ייתכן וחלק מפרטיו האישיים (לא כולל פרטי אמצעי התשלום) יועברו בצורה שאינה מוצפנת דרך רשתות.
</Typography>
<Typography variant="body1" paragraph>
  המשתמש מתחייב לא להעתיק, לשכפל, למכור ולהפיץ את תוכן האתר והשירותים אותו מציע. נאסר על המשתמש לשתף את חשבונו.
</Typography>
<Typography variant="body1" paragraph>
  המפעילה אינה אחראית לדיוק המידע שמופיע באתר. המידע המופיע באתר הוא כללי לנוחיות המשתמש בלבד. המפעילה שומרת את הזכות לשנות, למחוק ולעדכן את האתר ותוכנו בכל זמן וזוהי אחריות המשתמש להתעדכן בשינויים.
</Typography>
<Typography variant="body1" paragraph>
  כל התמונות המופיעות באתר הן להמחשה בלבד.
</Typography>
<Typography variant="body1" paragraph>
  המוצרים הנמכרים באתר הינם מוצרים דיגיטליים לכן לא ניתן יהיה לקבל החזר כספי בגינן לאחר התשלום.
</Typography>
<Typography variant="body1" paragraph>
  בנוסף לאיסורים אשר מפורטים בהסכם זה, על המשתמש אסור להשתמש באתר זה ובכל תוכנו כאשר עושה: שימוש למטרות רעות; הפרת כל חוק ורגולציה מקומית ובין לאומית, הפרת זכויות יוצרים; העלבה, ניצול, פגיעה על רקע דתי, לאומי, מגדרי, על רקע גיל, העדפה מינית ונכות; שליחת מידע מוטעה ושגוי, העלאת וירוסים, תוכנות זדוניות וכל קוד אשר יפגע בעקיפין או בישירות באתר, באתרים אחרים, בשירותים המוצעים בהם ובאינטרנט ככלל; לאסוף ולעקוב אחרי מידע אישי של המשתמשים; לגרום לספאם; לגרום להפרעות בתוכנות הבטיחות של השירות, כל אתר רלוונטי והאינטרנט ככלל;
</Typography>
<Typography variant="body1" paragraph>
  האתר שומר לזכותו את הרשות להרחיק משתמשים מהאתר ולא לאפשר להם להשתמש בשירותיו כאשר מתבצעת הפרה של כללים אלו וכתוצאה מכל שיקול דעתו הבלעדי של האתר.
</Typography>
<Typography variant="body1" paragraph>
  האתר נכתב בלשון זכר מטעמי נוחות בלבד, אך מיועד לנשים וגברים כאחד. הניסוח באתר נתון לשינוי בשיקול צוות האתר וללא התרעה מוקדמת.
</Typography>
<Typography variant="body1" paragraph>
  על המשתמש להכיר ולאשר בכל שכל הזכויות, זכויות הקניין הקשורות לאתר ובקשר אליו שמורות לעידו שגב, וכי לא ירכוש ויקבל כל זכות, קניין וזיקה לאתר.
</Typography>
<Typography variant="body1" paragraph>
  כל זכויות הקניין הרוחני, לרבות הזכויות המוסריות, הזכויות הכלכליות, זכויות היוצרים, סימני המסחר, שמות מסחר, פטנטים, מדגמים, סודות מסחריים וכל זכות אחרת ביחס לאתר לרבות, אך לא רק, התוכן, העיצוב, העריכה וכן אופן הצגתו ועריכתו של המידע המופיע באתר והאתר עצמו שמורות באופן בלעדי למפעילה.
</Typography>
<Typography variant="body1" paragraph>
  מבלי לגרוע מהאמור לעיל, האתר, הדפים המקוונים הכלולים בו, הסיווג, הסידור וההצגה של המידע, וכל צורת המחשה אחרת הכלולים באתר הנם בבעלותה הבלעדית של המפעילה.
</Typography>
<Typography variant="body1" paragraph>
  אין להעתיק ו/או לשעתק ו/או לשכפל ו/או להכין יצירות נגזרות ו/או לשנות ו/או להתאים את תוכן האתר במלואו או בחלקו לצורך הפצה, פרסום, הצגה, ביצוע, העברה, שידור, העמדה לרשות הציבור, מכירה ו/או כל פעולה אחרת ללא קבלת אישור בכתב ומראש מהמפעילה.
</Typography>
<Typography variant="body1" paragraph>
  המשתמש מתחייב כי לא יפרסם ברבים את התוכן ו/או חלק ממנו, אלא בתנאים המפורטים באתר. כמו כן, מתחייב המשתמש כי לא יפרסם ברבים כל מוצר ו/או פלט של המידע, בין שהוא מודפס ובין שהוא ניתן כקובץ על גבי אמצעים מגנטיים או בכל צורה אחרת וכי לא יעתיק ו/או ישעתק ו/או ישכפל/או יכין יצירות נגזרות ו/או ישנה ו/או יתאים את תוכן האתר במלואו או בחלקו לצורך הפצה, פרסום, הצגה, ביצוע, העברה, שידור, העמדה לרשות הציבור, מכירה ו/או כל פעולה אחרת ללא קבלת אישור בכתב ומראש מהמפעילה.
</Typography>
<Typography variant="body1" paragraph>
  אתר זה והמידע, התכנים, החומרים, המוצרים והשירותים הכלולים בו ניתנים במצבם כפי שהם [AS IS] ובכפוף לזמינותם. האתר אינו מבטיח, שהשימוש באתר ובשירותיו יהיה רצוף, נטול בעיות ומאובטח באופן ודאי. למען הסר ספק מוצהר בזאת, כי האתר נוקט בצעדים משמעותיים על מנת לאבטח את האתר ולהבטיח את פעילותו הסדירה והתקינה. המשתמש מסכים כי ייתכן והשירות באתר יוסר או יופסק לתקופת זמן בלתי מוגבלת על פי שיקול דעתו הבלעדי של האתר ללא התראה מוקדמת למשתמשים. המשתמש מבטיח שהשימוש באתר הוא באחריותו המלאה. המשתמש מסכים כי אין באפשרות האתר להבטיח ולתת אחריות ודאית על המוצרים והשירותים הניתנים בו, על הפסקה, האטה, אובדן מידע, גנבת מידע, נזק למחשב, נזק לתשתית, הפרעה, וירוס, פרצה, תקלה, כשל חומרה ו/או תוכנה ו/או קווי תקשורת, או פגם כלשהו באבטחה, תהא סיבתם אשר תהא (להלן – “כשל אפשרי”). האתר, מנהליו, בעליו, עובדיו משרדיו ספקיו ומספקי השירות שלו לא יהיו אחראים לכל פציעה, פגיעה, אובדן כסף וטענה ישירה, עקיפה, מיוחדת מכל סוג, הכוללת נזקים מכל סוג שהוא, כולל הפסדי רווחים, הפסדי חסכונות, איבוד מידע וכל נזק אפשרי אשר נגרם משימוש באתר ומשירותיו למשתמש ו/או לכל צד ג’ בצורה ישירה ועקיפה.
</Typography>
<Typography variant="body1" paragraph>
  במידה והמפעילה לא תקפיד על ביצוע תנאי כלשהו מהתנאים או מההתחייבות הכלולים בהסכם זה, לא ייחשב הדבר כוויתור על זכות או סעד כלשהם שעשויים לעמוד לרשותה, ולא יפורש כוויתור על כל הפרה עתידית של התנאים או ההתחייבויות הכלולים בהסכם זה, אשר ימשיכו לפעול במלוא תוקפם.
</Typography>
<Typography variant="body1" paragraph>
  שום ויתור על ידי מי מהצדדים על הפרה כלשהי של הוראה מהוראות הסכם זה לא תיחשב כוויתור על הפרה עתידית או קודמת כלשהי של אותה הוראה או של הוראה אחרת.
</Typography>
          </SectionContainer>
        </motion.div>
      </PageContainer>
    </ThemeProvider>
  );
};

export default ContactAndPolicyPage;
